<template>
  <b-modal
    id="goods-group-modal"
    :title="isEditMode ? $t('goodsGroup.edit.title') : $t('goodsGroup.create.title')"
    :ok-title="isEditMode ? $t('goodsGroup.edit.ok') : $t('goodsGroup.create.ok')"
    :cancel-title="isEditMode ? $t('goodsGroup.edit.cancel') : $t('goodsGroup.create.cancel')"
    @hidden="reset"
    @ok="onOk"
  >
    <template v-slot:default>
      <b-form-group :label="$t('goodsGroup.groupTitle')">
        <b-form-input v-model.lazy="group.title[$i18n.locale]"></b-form-input>
      </b-form-group>
      <b-form-group :label="$t('goodsGroup.goods')">
        <v-select
          multiple
          v-model="selectedGoods"
          :options="options"
          :getOptionLabel="x => `${x.title[$i18n.locale]} - ${x.code}`"
          :reduce="x => x.title[$i18n.locale]"
        >
          <template v-slot:no-options>{{ $t("vselect.noOptions") }}</template>
        </v-select>
      </b-form-group>
      <b-form-group :label="$t('goodsGroup.description')">
        <b-form-textarea v-model.lazy="group.description"></b-form-textarea>
      </b-form-group>
      <div class="my-2" v-show="isEditMode">
        <b-button variant="danger" @click="onRemoveGroup">{{ $t("goodsGroup.edit.remove") }}</b-button>
        <b-modal
          id="remove-goods-group-modal"
          :title="$t('goodsGroup.remove.title')"
          :ok-title="$t('goodsGroup.remove.ok')"
          :cancel-title="$t('goodsGroup.remove.cancel')"
          ok-variant="danger"
          :ok-disabled="groupTitleForRemove !== group.title[$i18n.locale]"
          @ok="removeGroup"
        >
          <template v-slot:default>
            <b-form-group :label="$t('goodsGroup.remove.body')">
              <b-form-input v-model="groupTitleForRemove"></b-form-input>
            </b-form-group>
          </template>
        </b-modal>
      </div>
    </template>
  </b-modal>
</template>

<script>
import { EN, RU, LT } from "@/const/langs.js";
import { UPDATE_GOODS_GROUP, OPEN_EDIT_GOODS_GROUP_MODAL } from "@/const/events.js";

import { mapActions } from "vuex";

export default {
  data() {
    return {
      isEditMode: false,
      oldGroupTitle: "",
      oldLocale: "",
      goodsDictionary: [],
      group: {
        title: {
          ru: "",
          en: "",
          lt: ""
        },
        goods: [],
        description: ""
      },
      groupTitleForRemove: ""
    };
  },
  computed: {
    selectedGoods: {
      get: function() {
        return this.group.goods;
      },
      set: function(values) {
        this.group.goods = this.goodsDictionary.filter(x => values.some(y => y === x.title[this.$i18n.locale]));
      }
    },
    options() {
      return this.goodsDictionary.filter(x =>
        this.selectedGoods.every(y => y.title[this.$i18n.locale] !== x.title[this.$i18n.locale])
      );
    }
  },
  methods: {
    ...mapActions([
      "goods/getBaseInfoList",
      "goodsGroup/addGroup",
      "goodsGroup/getGroup",
      "goodsGroup/update",
      "goodsGroup/remove"
    ]),
    reset() {
      this.isEditMode = false;
      this.oldGroupTitle = "";
      this.oldLocale = "";
      this.groupTitleForRemove = "";
      this.group = {
        title: {
          ru: "",
          en: "",
          lt: ""
        },
        goods: []
      };
    },
    onOk(e) {
      e.preventDefault();

      if (this.group.title[this.$i18n.locale] === "") {
        alert(this.$t("goodsGroup.emptyGroupTitleErrorMsg"));
        return;
      }

      if (this.isEditMode) {
        // update group
        this["goodsGroup/update"]({
          locale: this.oldLocale,
          oldGroupTitle: this.oldGroupTitle,
          newGroupTitle: this.group.title[this.oldLocale],
          goodsTitles: this.group.goods.map(x => x.title),
          newGroupDescription: this.group.description
        })
          .then(() => {
            this.$bus.emit(UPDATE_GOODS_GROUP);
            this.$bvModal.hide("goods-group-modal");
          })
          .catch(() => alert(this.$t("goodsGroup.updateGroupErrorMsg")));
      } else {
        // create new group
        let title = {
          [EN]: this.group.title[this.$i18n.locale] + `_${EN}`,
          [RU]: this.group.title[this.$i18n.locale] + `_${RU}`,
          [LT]: this.group.title[this.$i18n.locale] + `_${LT}`
        };

        title[this.$i18n.locale] = this.group.title[this.$i18n.locale];

        this.group.title = title;
        this["goodsGroup/addGroup"]({
          title: this.group.title,
          description: this.group.description,
          goods: this.group.goods
        })
          .then(() => {
            this.$bus.emit(UPDATE_GOODS_GROUP);
            this.$bvModal.hide("goods-group-modal");
          })
          .catch(() => alert(this.$t("goodsGroup.addGroupErrorMsg")));
      }
    },
    uploadGroupData({ title, locale }) {
      this["goodsGroup/getGroup"]({ title, locale }).then(resp => {
        this.group = resp.data;
        this.isEditMode = true;
        this.oldGroupTitle = title;
        this.oldLocale = locale;
        this.$bvModal.show("goods-group-modal");
      });
    },
    onRemoveGroup(e) {
      e.preventDefault();
      if (this.group.goods.length > 0) {
        this.$bvModal.show("remove-goods-group-modal");
        return;
      }

      this.removeGroup();
    },
    removeGroup() {
      this["goodsGroup/remove"]({
        title: this.oldGroupTitle,
        locale: this.oldLocale
      }).then(() => {
        this.$bus.emit(UPDATE_GOODS_GROUP);
        this.$bvModal.hide("remove-goods-group-modal");
        this.$bvModal.hide("goods-group-modal");
      });
    }
  },
  mounted() {
    this["goods/getBaseInfoList"]().then(resp => (this.goodsDictionary = resp.data));
    this.$bus.on(OPEN_EDIT_GOODS_GROUP_MODAL, this.uploadGroupData);
  },
  beforeDestroy() {
    this.$bus.off(OPEN_EDIT_GOODS_GROUP_MODAL, this.uploadGroupData);
  }
};
</script>
