<template>
  <b-container fluid>
    <goods-group-list />
  </b-container>
</template>

<script>
import GoodsGroupList from "@/components/GoodsGroupList.vue";

export default {
  components: {
    "goods-group-list": GoodsGroupList
  }
};
</script>
