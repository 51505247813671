<template>
  <b-container fluid>
    <b-row class="mb-2">
      <b-col>
        <b-button v-b-modal.goods-group-modal>{{ $t("goodsGroup.addGroupBtn") }}</b-button>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-table :fields="fields" :items="groupList" responsive>
          <template v-slot:top-row>
            <td>
              <b-input v-model.trim="filter.title" :placeholder="$t('goodsGroup.groupTitle')"></b-input>
            </td>
            <td>
              <b-input v-model.trim="filter.description" :placeholder="$t('goodsGroup.description')"></b-input>
            </td>
            <td>
              <b-input v-model.trim="filter.count" :placeholder="$t('goodsGroup.count')"></b-input>
            </td>
          </template>
          <template v-slot:cell(title)="row">
            <b-link @click="onOpenEditGroupModal(row.value[$i18n.locale])">{{ row.value[$i18n.locale] }}</b-link>
          </template>
        </b-table>
        <goods-group-modal />
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import GoodsGroupModal from "@/components/GoodsGroupModal.vue";

import { UPDATE_GOODS_GROUP, OPEN_EDIT_GOODS_GROUP_MODAL } from "@/const/events.js";
import comparator from "@/services/string-comparator.js";
import { mapActions } from "vuex";

export default {
  components: {
    "goods-group-modal": GoodsGroupModal
  },
  data() {
    return {
      items: [],
      filter: {
        title: "",
        count: "",
        description: ""
      }
    };
  },
  computed: {
    fields() {
      return [
        { key: "title", label: this.$t("goodsGroup.groupTitle"), sortable: true },
        { key: "description", label: this.$t("goodsGroup.description") },
        { key: "count", label: this.$t("goodsGroup.count"), sortable: true }
      ];
    },
    groupList() {
      return this.items.filter(
        x =>
          comparator.compareRows(x.title[this.$i18n.locale], this.filter.title) &&
          x.count.toString().includes(this.filter.count) &&
          comparator.compareRows(x.description || "", this.filter.description)
      );
    }
  },
  methods: {
    ...mapActions(["goodsGroup/getGroupsInfo"]),
    onOpenEditGroupModal(title) {
      this.$bus.emit(OPEN_EDIT_GOODS_GROUP_MODAL, {
        title,
        locale: this.$i18n.locale
      });
    },
    uploadGroupsInfo() {
      this["goodsGroup/getGroupsInfo"]().then(resp => (this.items = resp.data));
    }
  },
  mounted() {
    this.$bus.on(UPDATE_GOODS_GROUP, this.uploadGroupsInfo);
    this.uploadGroupsInfo();
  },
  beforeDestroy() {
    this.$bus.off(UPDATE_GOODS_GROUP, this.uploadGroupsInfo);
  }
};
</script>
